<template>
  <sui-dropdown
    button
    class="icon"
    floating
    icon="world"
    labeled
    @click="changeLang()"
    :text="lang == 'en' ? 'العربية' : 'English'"
    :options="languages"
    v-model="lang"
  />
</template>

<script>
export default {
  mounted() {
    if(localStorage.getItem("lang") == null) {
      localStorage.setItem('lang' , 'ar');
      this.lang = 'en';
    }
    else {
      this.lang = localStorage.getItem("lang") == 'ar' ? 'en': 'ar'
    }
  },
  data() {
    return {
      lang: localStorage.getItem("lang"),
      languages: [
        { key: "ar", text: "العربية", value: "ar" },
        { key: "en", text: "English", value: "en" },
      ],
    };
  },
  methods: {
    changeLang() {
      if (this.lang != localStorage.getItem("lang")) {
        localStorage.setItem("lang", this.lang);
        this.$router.go();
      }
    },
  },
};
</script>

<style>
</style>