import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/addClient',
    name: 'addClient',
    component: () => import('../views/clients/AddClient.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('../components/Redirect.vue')
  },
  {
    path: '/',
    component: () => import('../views/home/Home.vue'),
    children: [
      {
        path: 'clients',
        name: 'clients',
        component: () => import('../views/clients/Clients.vue'),
      },
      {
        path: 'attendance',
        name: 'attendance',
        component: () => import('../views/attendance/Attendance.vue'),
      },
      {
        path: 'broadcasts',
        name: 'broadcasts',
        component: () => import('../views/broadcasts/Broadcasts.vue'),
      },
      {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () => import('../views/subscriptions/Subscriptions.vue'),
      },
      {
        path: 'pts',
        name: 'pts',
        component: () => import('../views/pts/PTs.vue'),
      },
      {
        path: 'offers',
        name: 'offers',
        component: () => import('../views/offers/Offers.vue'),
      },
      {
        path: 'mediaes',
        name: 'mediaes',
        component: () => import('../views/mediaes/Mediaes.vue'),
      },
      {
        path: 'coaches',
        name: 'coaches',
        component: () => import('../views/coaches/Coaches.vue'),
      },
      {
        path: 'supervisors',
        name: 'supervisors',
        component: () => import('../views/supervisors/Supervisors.vue'),
      },
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import('../components/404.vue')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../components/500.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== '404' && to.name !== '500') {
    if(sessionStorage.getItem('token') == null) {
      console.log('inside 1 if');
      if(localStorage.getItem('token') == null) {
        console.log('inside 2 if');
        if(to.name == 'login') next()
        else next({ name: 'login' })
      }
      else {
        console.log('inside 1 else');
        sessionStorage.setItem('token' ,localStorage.getItem('token'));
        sessionStorage.setItem('name' ,localStorage.getItem('name'));
        sessionStorage.setItem('role' ,localStorage.getItem('role'));
        next({ name: 'login' })
      }
    }
      else if (to.name === "redirect" | to.name === "login" | to.name === "/") {
        switch(sessionStorage.getItem('role')) {
          case 'owner' : next({ name: 'incomeReport' });
          break;
          case 'supervisor' : next({name : 'orders'})
          break;
          default : next({name : 'addOrder'})
        }
      }
      else next()
    }
    else next()
  })


export default router
