<template>
  <div class="sidebar">
    <div class="sidebar-inner" style="border-right: 1px #ddd solid">
      <!-- ### $Sidebar Header ### -->
      <div class="sidebar-logo" style="border-bottom: white">
        <div class="peers ai-c fxw-nw" style="padding: 20 20px">
          <div class="peer peer-greed">
            <a
              class="sidebar-link td-n"
              href="#"
              ><div class="peers ai-c fxw-nw">
                <div class="peer">
                  <div class="logo">
                    <img src="@/assets/static/images/fitness.png" alt="" height="100"/>
                  </div>
                </div>
                <div class="peer peer-greed">
                </div>
              </div></a
            >
          </div>
          <div class="peer">
            <div class="mobile-toggle sidebar-toggle">
              <a href="" class="td-n">
                <i class="ti-arrow-circle-left"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- ### $Sidebar Menu ### -->
      <ul class="sidebar-menu scrollable pos-r">
        <div class="mT-30"></div>
        <li v-if="getRole() == 'owner'" id="li1" class="nav-item dropdown">
          <a class="dropdown-toggle" href="javascript:void(0);" v-on:click="fn('1')">
            <span class="icon-holder"><i class="c-blue-700  ti-pie-chart"></i> </span>
            <span class="title">{{$t('reports') }}</span> 
            <span class="arrow"><i class="ti-angle-right"></i></span></a>
            <ul class="dropdown-menu">
            </ul>
          </li>
        <li v-if="getRole() == 'owner'" class="nav-item" >
          <router-link class="sidebar-link"  to="/supervisors">
            <span class="icon-holder">
              <i class="c-blue-700 c-blue-700 ti-user "></i>
            </span>
            <span class="title">{{ $t('supervisors') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link"  to="/clients">
            <span class="icon-holder">
              <i class="c-blue-700 ti-agenda"></i>
            </span>
            <span class="title">{{ $t('clients') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/mediaes">
            <span class="icon-holder">
              <i class="c-blue-700  ti-rss-alt"></i>
            </span>
            <span class="title">{{$t('media')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/coaches">
            <span class="icon-holder">
              <i class="c-blue-500 ti-id-badge"></i>
            </span>
            <span class="title">{{$t('coaches')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/subscriptions">
            <span class="icon-holder">
              <i class="c-blue-700   ti-package"></i>
            </span>
            <span class="title">{{$t('subscriptions')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/pts">
            <span class="icon-holder">
              <i class="c-blue-700   ti-headphone-alt"></i>
            </span>
            <span class="title">{{$t('pT')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/offers">
            <span class="icon-holder">
              <i class="c-blue-700   ti-gift"></i>
            </span>
            <span class="title">{{$t('offers')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/subscriptions">
            <span class="icon-holder">
              <i class="c-blue-700 ti-clipboard"></i>
            </span>
            <span class="title">{{$t('enteryLog')}}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="sidebar-link" to="/broadcasts">
            <span class="icon-holder">
              <i class="c-blue-700   ti-email"></i>
            </span>
            <span class="title">{{$t('broadcast')}}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods : {
    getRole() {
      return sessionStorage.getItem('role');
    },
    fn: function(id)  {
      var stringId = '#li'+id;
    if($( stringId).attr('class') == 'nav-item dropdown open') {
      $( stringId ).removeClass('open');
    } 
    else {
      $( stringId ).addClass('open');
    }
  }
  }
};
</script>

<style>
</style>