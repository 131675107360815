<template>
  <div
    class="header navbar"
    style="border-color: #ddd; border-width: 0 0 1px 0"
  >
    <div class="header-container">
      <ul class="nav-left">
        <li>
          <a
            id="sidebar-toggle"
            class="sidebar-toggle"
            href="javascript:void(0)"
            v-on:click="fn"
            ><i class="ti-menu"></i
          ></a>
        </li>
      </ul>
      <ul class="nav-right">
        <li style="padding-top:16px; margin:0"><button type="button" @click="showPassword()" class="btn btn-warning"><i class="ti ti-lock" > </i></button>
            </li>
        <li style="padding-top:13px; margin:0 20px">
          <sui-button @click="goAddClient"  style="color:black" icon="tablet" />
        </li>
        <li style="padding-top:13px">
          <lang/>
        </li>
        <li class="dropdown">
          <a
            href=""
            class="dropdown-toggle no-after peers fxw-nw ai-c lh-1"
            data-toggle="dropdown"
          >
            <div class="peer mR-10">
              <img
                class="w-2r bdrs-50p"
                src="@/assets/static/images/user.png"
                alt=""
              />
            </div>
            <div class="peer">
              <span class="fsz-sm c-grey-900">{{ getName() }}</span>
            </div>
          </a>
          <ul class="dropdown-menu fsz-sm">
            <li role="separator" class="divider"></li>
            <li>
              <a class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                <i class="ti-power-off mR-10"></i>
                <span @click="logout()">{{ $t('logout') }}</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="modal" id="password" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t('password') }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <input type="password" class="form-control" v-model="oldPassword" :placeholder="this.$t('oldPassword')" />
          </div>
        </div>
        <div class="row mT-10">
          <div class="col-sm-12">
            <input type="password" class="form-control" v-model="password" :placeholder="this.$t('password')" />
          </div>
        </div>
      </div>
      <div v-if="!loading" class="modal-footer">
        <button type="button" class="btn btn-primary" @click="updatePassword">{{ $t('save') }}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('close') }}</button>
      </div>
      <div v-else class="modal-footer">
        <loading />
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import { data } from 'jquery';
import Lang from './Lang.vue';
export default {
  data() {
    return {
      password: '',
      oldPassword:'',
      loading: false
    }
  },
  methods: {
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.go();
    },
    fn: function()  {
    if($( "body" ).attr('class') == 'app is-collapsed') {
      $( "body" ).removeClass('is-collapsed');
    } 
    else {
      $( "body" ).addClass('is-collapsed');
    }
  },
  getName() {
    return sessionStorage.getItem('name');
  },
  goAddClient() {
    this.$router.push("/addClient");
  },
  showPassword() {
    this.password = '';
    this.oldPassword = '';
    $('#password').modal('show')
  },
  updatePassword: function () {
    this.loading = true;
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .patch(
            "/backend/supervisors/updatePassword",
            {
              password : this.password,
              old_password: this.oldPassword
            },
            { headers }
          )
  
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                $('#password').modal('hide')
                  this.success(this.$t("success"));
                  break;
              } 
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                  case 402:
                  this.error(this.$t("wrongOldpassword"));
                    break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            },
            
          );
      },
  error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function () {
        this.$toast.success(this.$t("success"), {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
  },
};
</script>

<style>
.modal-backdrop {
    z-index: 97 !important; /* Adjust the z-index value as needed */
}
.modal {
    z-index: 200; /* Adjust the z-index value as needed */
}
</style>